import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet"
import CustomButton from "../CustomButton"

const MobileCarInspection = () => {
  const { t, i18n } = useTranslation()
  const isRTL = i18n.dir() === "rtl"

  return (
    <div
      className={`mobile-inspection-page bg-gradient-to-b from-gray-50 to-white min-h-screen ${isRTL ? "text-right" : "text-left"}`}
    >
      <Helmet>
        <title>{t("portable_inspection_metaTitle")}</title>
        <meta name="description" content={t("portable_inspection_metaDescription")} />
        <meta name="keywords" content={t("portable_inspection_keywords")} />
        <link rel="canonical" href={`${window.location.origin}/mobile-car-inspection`} />
      </Helmet>

      <div className="container mx-auto px-4 py-8">
        {/* Hero Section */}
        <section className="flex flex-col lg:flex-row items-center gap-12 py-12 md:py-20">
          <div className="flex-1 max-w-xl">
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-800 leading-tight">
              {t("portable_inspection_title")}
            </h1>
            <div className="h-1 w-20 bg-green-500 my-6"></div>
            <p className="mt-4 text-lg text-gray-600 leading-relaxed">{t("portable_inspection_description")}</p>
            <div className="mt-10">
              <a
                href="https://wa.me/971561868236?text=Hi,%20I%20need%20the%20mobile%20inspection%20service."
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block"
              >
                <CustomButton
                  title={t("start_service")}
                  buttonStyle="bg-green-500 text-white px-8 py-4 rounded-full hover:bg-green-600 transition-all duration-300 transform hover:scale-105 shadow-lg text-lg font-medium"
                />
              </a>
            </div>
          </div>
          <div className="flex-1 mt-10 lg:mt-0">
            <div className="rounded-xl overflow-hidden shadow-2xl transform hover:scale-[1.02] transition-transform duration-300 max-w-md mx-auto">
              <img
                src="https://germanexperts.ae/wp-content/themes/MaxArt/assets/img/Our-products/2024/360-Inspection/360-inspections-mobile.webp"
                alt={t("portable_inspection_title")}
                className="w-full h-auto object-cover max-h-[500px]"
                loading="lazy"
              />
            </div>
          </div>
        </section>

        {/* Additional Content Section */}
        <section className="py-12 md:py-16">
          <div className="bg-white rounded-2xl shadow-lg p-8 md:p-10">
            <div className="mb-8">
              <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-6">{t("portable_inspection_title")}</h2>
              <div className="space-y-6">
                <p className="text-lg text-gray-600 leading-relaxed">{t("portable_inspection_paragraph1")}</p>
                <p className="text-lg text-gray-600 leading-relaxed">{t("portable_inspection_paragraph2")}</p>
                <p className="text-lg text-gray-600 leading-relaxed">{t("portable_inspection_paragraph3")}</p>
              </div>
            </div>

            <div className="mt-8 bg-gray-50 p-6 rounded-xl">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">{t("key_benefits")}</h3>
              <ul className={`space-y-4 ${isRTL ? "pr-5" : "pl-5"}`}>
                {["portable_inspection_bullet1", "portable_inspection_bullet2", "portable_inspection_bullet3"].map((bulletKey) => (
                  <li key={bulletKey} className="flex items-start">
                    <span className="inline-flex items-center justify-center h-6 w-6 rounded-full bg-green-500 text-white mr-3 flex-shrink-0">
                      ✓
                    </span>
                    <span className="text-gray-600">{t(bulletKey)}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="mt-10 text-center">
              <a
                href="https://wa.me/971561868236?text=Hi,%20I%20need%20the%20mobile%20inspection%20service."
                target="_blank"
                rel="noopener noreferrer"
              >
                <CustomButton
                  title={t("start_service")}
                  buttonStyle="bg-green-500 text-white px-6 py-3 rounded-full hover:bg-green-600 transition-all duration-300 shadow-md text-lg font-medium"
                />
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default MobileCarInspection