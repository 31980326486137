import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import CustomButton from "./CustomButton";
import { TextGenerateEffect } from "../ui/TextGenerationEffect";
import heroImg from '../assets/car-inspected.png';

const ConversionTrackingScripts = () => (
    <>
        {/* Google tag (gtag.js) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16721393829"></script>
        <script>
            {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'AW-16721393829');
            `}
        </script>
        {/* Event snippet for عملية شراء conversion page */}
        <script>
            {`
                function gtag_report_conversion(url) {
                    var callback = function () {
                        if (typeof(url) != 'undefined') {
                            window.location = url;
                        }
                    };
                    gtag('event', 'conversion', {
                        'send_to': 'AW-16721393829/4kWfCNKhoNgZEKXxsKU-',
                        'transaction_id': '',
                        'event_callback': callback
                    });
                    return false;
                }
            `}
        </script>
    </>
);

const Hero = () => {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir() === 'rtl';

    // Function to handle button click event tracking
    const trackButtonClick = (buttonName, url) => {
        if (window.gtag_report_conversion) {
            window.gtag_report_conversion(url);
        }
        if (window.gtag) {
            window.gtag('event', 'click', {
                event_category: 'Hero Section',
                event_label: buttonName,
                value: 1
            });
        }
    };

    // Function to track a successful referral only once per session
    const trackSuccessfulReferral = () => {
        if (!sessionStorage.getItem('referred')) {
            if (window.gtag) {
                window.gtag('event', 'successful_referral', {
                    event_category: 'Referrals',
                    event_label: 'Unique Referral',
                    value: 1
                });
            }
            sessionStorage.setItem('referred', 'true');
        }
    };

    useEffect(() => {
        trackSuccessfulReferral();
    }, []);

    return (
        <div>
            {/* Section 1: Main Car Inspection */}
            <section className={`flex flex-col lg:flex-row gap-5 relative z-0 max-w-[1440px] mx-auto ${isRTL ? 'lg:flex-row-reverse' : ''}`}>
                <Helmet>
                    <title>{t('hero_metaTitle')}</title>
                    <meta name="description" content={t('hero_metaDescription')} />
                    <meta name="keywords" content={t('hero_metaKeywords')} />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                {/* Google Ads Conversion Tracking */}
                <ConversionTrackingScripts />

                <div className={`flex-1 pt-36 px-6 sm:px-16 ${isRTL ? 'text-right' : 'text-left'}`}>
                    <TextGenerateEffect
                        words={t('hero_title')}
                        className="font-extrabold"
                    />
                    <p className="font-light text-[27px] mt-5 text-black-100">
                        {t('hero_description')}
                    </p>
                    <div className={`flex gap-4 mt-10 ${isRTL ? 'flex-row-reverse' : ''}`}>
                        <a
                            href="https://maps.app.goo.gl/pCJaz6oGmEgim6gk6"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => trackButtonClick('Visit Us', 'https://maps.app.goo.gl/pCJaz6oGmEgim6gk6')}
                        >
                            <CustomButton
                                title={t('visit_us')}
                                buttonStyle="bg-primary-blue text-white rounded-full hover:bg-blue-700 transition-colors duration-300"
                            />
                        </a>
                        <a
                            href="https://wa.me/971561868236?text=I'd%20like%20to%20book%20an%20inspection%20for%20my%20vehicle."
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => {
                                trackButtonClick('Book Inspection', 'https://wa.me/971561868236?text=I\'d%20like%20to%20book%20an%20inspection%20for%20my%20vehicle.');
                                trackSuccessfulReferral();
                            }}
                        >
                            <CustomButton
                                title={t('book_inspection')}
                                buttonStyle="bg-cyan-500 text-white rounded-full hover:bg-cyan-600 transition-colors duration-300 shadow-md"
                            />
                        </a>
                    </div>
                </div>

                <div className="flex xl:flex-[1.5] justify-end items-end xl:h-screen w-full">
                    <div className="relative z-0 xl:w-full w-[90%] h-[590px] xl:h-full">
                        <img src={heroImg} alt={t('hero_imageAlt')} className="object-contain w-full h-full" />
                    </div>
                    <div className="bg-hero-bg absolute xl:-top-24 xl:-right-1/2 -right-1/4 bg-repeat-round -z-10 w-full xl:h-screen h-[590px] overflow-hidden" />
                </div>
            </section>

            {/* Section 2: Mobile Car Inspection */}
            <section className={`flex flex-col lg:flex-row gap-5 relative z-0 max-w-[1440px] mx-auto mt-20 ${isRTL ? 'lg:flex-row-reverse' : ''}`}>
                <div className={`flex-1 pt-36 px-6 sm:px-16 ${isRTL ? 'text-right' : 'text-left'}`}>
                    <TextGenerateEffect
                        words={t('portable_inspection_title')}
                        className="font-extrabold"
                    />
                    <p className="font-light text-[27px] mt-5 text-black-100">
                        {t('portable_inspection_description')}
                    </p>
                    <div className={`flex gap-4 mt-10 ${isRTL ? 'flex-row-reverse' : ''}`}>
                        <a 
                            href="https://wa.me/971561868236?text=Hi,%20I%20need%20the%20mobile%20inspection%20service." 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            onClick={() => trackButtonClick('Start Service', 'https://wa.me/971561868236?text=Hi,%20I%20need%20the%20mobile%20inspection%20service.')}
                        >
                            <CustomButton
                                title={t('start_service')}
                                buttonStyle="bg-green-500 text-white rounded-full hover:bg-green-600 transition-colors duration-300 shadow-md"
                            />
                        </a>
                    </div>
                </div>

                <div className="flex xl:flex-[1.5] justify-end items-end xl:h-screen w-full">
                    <div className="relative z-0 xl:w-full w-[90%] h-[590px] xl:h-full">
                        <img src={heroImg} alt={t('portable_inspection_title')} className="object-contain w-full h-full" />
                    </div>
                    <div className="bg-hero-bg absolute xl:-top-24 xl:-right-1/2 -right-1/4 bg-repeat-round -z-10 w-full xl:h-screen h-[590px] overflow-hidden" />
                </div>
            </section>
        </div>
    );
};

export default Hero;
